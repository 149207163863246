const axios = require('axios');
import BrowserStorageService from '@/utils/service/browserStorage';

const baseURL = process.env.NEXT_PUBLIC_SERVER_URL;

const http = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Pragma: 'no-cache',
    'X-Project-ID': process.env.NEXT_PUBLIC_PROJECT_ID,
  },
});

http.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      BrowserStorageService.clearAllToken();
      BrowserStorageService.clearAccessToken();
      window.location.href = '/';
    }
    throw error;
  }
);

const updateAuthorizationToken = async (token) => {
  http.defaults.headers.Authorization = `Bearer ${token}`;
};

export { http, updateAuthorizationToken };
