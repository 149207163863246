/* eslint-disable @next/next/no-assign-module-variable */
import { Wallet } from 'ethers';

const loadRPC = async () => {
  const module = await import('@/utils/Web3Auth/web3RPC');
  return module.default;
};

const getAddress = async (provider) => {
  if (!provider) {
    console.log('provider not initialized yet', provider);
    return;
  }
  const RPC = await loadRPC();
  const rpc = new RPC(provider);
  const accounts = await rpc.getAccounts();
  return accounts[0];
};

const signMessage = async (challenge, provider) => {
  if (!provider) {
    console.log('provider not initialized yet', provider);
    return;
  }
  const RPC = await loadRPC();
  const rpc = new RPC(provider);
  const privateKey = await rpc.getPrivateKey();
  const wallet = new Wallet(privateKey);
  const signature = await wallet.signMessage(challenge);
  return signature;
};

export { getAddress, signMessage };
