const { create } = require('zustand');
import HansoloService from '@/utils/service/hansolo';
import useAccountStore from '../useAccountStore';

const useConstantStore = create((set) => ({
  packageMap: [],
  exChangeRate: 0,
  setPackageMap: (value) => set({ packageMap: value }),
  setExchangeRate: (value) => set({ exChangeRate: value }),
  getExchangeRate: async () => {
    const exchangeRate = await HansoloService.getExchangeRate();
    set({ exChangeRate: exchangeRate?.data?.data });
  },
  getPackageMap: async (value) => {
    const accountType = useAccountStore.getState().accountType;
    const packages = await HansoloService.getDepositAmounts();
    const currency = accountType === 'polygon' ? 'RBW' : 'USD';
    set({ packageMap: packages.data?.data?.[value.app]?.[currency] });
  },
}));

export default useConstantStore;
