import { create } from 'zustand';

const useLoadingStore = create((set) => ({
  loading: false,
  processing: false,
  setProcessing: (processing) => set({ processing }),
  setLoading: (loading) => set({ loading }),
}));

export default useLoadingStore;
