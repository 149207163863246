import { getAddress, signMessage } from '@/utils/functions';
import { etherToWeiWithDecimals } from '@/utils/helpers';
import BrowserStorageService from '@/utils/service/browserStorage';
import HansoloService from '@/utils/service/hansolo';
import { updateAuthorizationToken } from '@/utils/service/http';
import WertWidget from '@wert-io/widget-initializer';
import { signSmartContractData } from '@wert-io/widget-sc-signer';
import { utils } from 'ethers';
import { create } from 'zustand';
import useAccountStore from '../useAccountStore';
import useModalStore from '../useModalStore';

const useWeb3AuthStore = create((set, get) => {
  return {
    web3auth: null,
    provider: null,
    email: null,
    setEmail: (emailData) => set({ email: emailData }),
    setWeb3Auth: (web3authData) => set({ web3auth: web3authData }),
    setProvider: (providerData) => set({ provider: providerData }),
    connectWalletWeb3: (web3authProvider) => connectWalletWeb3(web3authProvider),
    Web3AuthAuthenticateUser: () => {
      const state = get();
      const web3authData = state.web3auth;
      if (web3authData) {
        Web3AuthAuthenticateUser(web3authData);
      } else {
        console.error('web3auth data is not available');
      }
    },
    PaypalWithdrawal: async (amount, paypalAddress) => {
      const selectedGameId = useAccountStore.getState().selectedGameId;
      try {
        const accountType = useAccountStore.getState().accountType;
        const walletAddress = useAccountStore.getState().walletAddress;
        const setCompletedPaypalWithdraw = useModalStore.getState().setCompletedPaypalWithdraw;
        const state = get();
        const web3authData = state.web3auth;
        if (web3authData) {
          const withdrawResp = await HansoloService.withdraw(
            accountType,
            walletAddress,
            'USD',
            amount,
            paypalAddress,
            selectedGameId.app
          );
          setCompletedPaypalWithdraw(true);
          return true;
        } else {
          console.error('web3auth data is not available');
        }
        getBalance();
      } catch (error) {
        console.error('Error withdrawing', error);
        if (error?.response?.status === 409) {
          setWithdrawToManyRequest(true);
          setWithdrawError(true);
        }
        setWithdrawError(true);
      }
    },
    signSmcPolygon: async (amount, appId, walletAddress, redirectUrl) => {
      const decimals = process.env.NEXT_PUBLIC_USDC_DECIMALS;
      const wei = etherToWeiWithDecimals(amount, decimals);
      const scAddress = process.env.NEXT_PUBLIC_SURF_BANK_ADDRESS;
      const USDCContract = process.env.NEXT_PUBLIC_USDC_CONTRACT_ADDRESS;
      const commodity = process.env.NEXT_PUBLIC_WERT_COMMODITY;
      const network = process.env.NEXT_PUBLIC_WERT_NETWORK;

      const partnerId = process.env.NEXT_PUBLIC_WERT_PARTNER;
      const privateKey = process.env.NEXT_PUBLIC_WERT_PRIVATE;
      const origin = process.env.NEXT_PUBLIC_WERT_ORIGIN;

      const abi = require('@/utils/service/contracts/SurfBank.json');
      const functionName = 'deposit';
      const depositAbi = abi.find((item) => item.name === functionName && item.type === 'function');

      const functionParams = [walletAddress, wei, USDCContract];
      const functionInterface = new utils.Interface([depositAbi]);
      const inputData = functionInterface.encodeFunctionData(functionName, functionParams);

      const signedData = signSmartContractData(
        {
          address: walletAddress,
          commodity: commodity,
          network: network,
          commodity_amount: amount,
          sc_address: scAddress,
          sc_input_data: inputData,
        },
        privateKey
      );

      const otherWidgetOptions = {
        partner_id: partnerId,
        click_id: appId, // unique id of purchase in your system
        origin: origin, // this option needed only for this example to work
        listeners: {
          loaded: () => console.log('loaded'),
          'payment-status': (data) => {
            const queryString = buildQueryString({
              success: true,
              transactionHash: data?.tx_id,
            });
            if (data?.status === 'success' && redirectUrl) openURL(queryString, redirectUrl);
          },
          error: (data) => {
            if (redirectUrl) {
              const queryString = buildQueryString({
                success: false,
                error: data?.message,
              });
              openURL(queryString);
            }
          },
        },
      };

      const nftOptions = {
        extra: {
          item_info: {
            author: 'Unicorn Party',
            image_url: 'https://unicornparty.com/footer-logo.png',
            name: 'Unicorn Party Funds',
            seller: 'Unicorn Party',
          },
        },
      };

      const wertWidget = new WertWidget({
        ...signedData,
        ...otherWidgetOptions,
        ...nftOptions,
      });

      wertWidget.mount();
    },
  };
});

export default useWeb3AuthStore;

const connectWalletWeb3 = async (web3authProvider) => {
  const setAccessToken = useAccountStore.getState().setAccessToken;
  const setWalletAddress = useAccountStore.getState().setWalletAddress;
  const setIsAdmin = useAccountStore.getState().setIsAdmin;
  const setAccountType = useAccountStore.getState().setAccountType;
  const setProvider = useWeb3AuthStore.getState().setProvider;
  const setLoggedIn = useAccountStore.getState().setLoggedIn;
  const address = await getAddress(web3authProvider);
  setWalletAddress(address);
  setAccountType('web3auth_polygon');
  const challengeResp = await HansoloService.getChallenge(address);
  const { challenge } = challengeResp.data;
  const signature = await signMessage(challenge, web3authProvider);
  const loginResp = await HansoloService.login(challenge, signature, address, null);
  const loginData = loginResp.data;
  if (loginData?.role === 7) {
    setIsAdmin(true);
    BrowserStorageService.setIsAdmin(true);
  }
  setWalletAddress(address);
  setAccessToken(loginData.token);
  BrowserStorageService.setAccessToken(loginData.token);
  updateAuthorizationToken(loginData.token);
  setProvider(web3authProvider);
  setLoggedIn(true);
};

const Web3AuthAuthenticateUser = async (web3auth) => {
  if (!web3auth) {
    return;
  }
  const idToken = await web3auth.authenticateUser();
  return idToken.idToken;
};

const buildQueryString = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

const openURL = (queryString, redirectUrl) => {
  const url = redirectUrl + '?' + queryString;
  window.open(url);
};
