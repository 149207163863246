/* eslint-disable import/no-anonymous-default-export */
const ACCESS_TOKEN = 'UserAccessToken';
const getKey = (key) => `${process.env.NEXT_PUBLIC_NODE_ENV === 'PRODUCTION' ? 'PRODUCTION' : 'DEVELOPMENT'}${key}`;

export default {
  // ACCESS TOKEN
  getAccessToken() {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(getKey(ACCESS_TOKEN));
    }
  },
  setAccessToken(accessToken) {
    if (typeof window !== 'undefined') {
      localStorage.setItem(getKey(ACCESS_TOKEN), accessToken);
    }
  },
  clearAccessToken() {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(getKey(ACCESS_TOKEN));
    }
  },
  clearAllToken() {
    if (typeof window !== 'undefined') {
      localStorage.clear();
    }
  },
  // COOKIES
  getCookiesConsentShown() {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(getKey('CookiesConsentShown')) === 'true';
    }
  },
  setCookiesConsentShown(value) {
    if (typeof window !== 'undefined') {
      return localStorage.setItem(getKey('CookiesConsentShown'), value.toString());
    }
  },
  getAnalyticsCookiesAllowed() {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(getKey('AnalyticsCookiesAllowed')) === 'true';
    }
  },
  setAnalyticsCookiesAllowed(value) {
    if (typeof window !== 'undefined') {
      return localStorage.setItem(getKey('AnalyticsCookiesAllowed'), value.toString());
    }
  },
  setFunctionalCookiesAllowed(value) {
    if (typeof window !== 'undefined') {
      return localStorage.setItem(getKey('FunctionalCookiesAllowed'), value.toString());
    }
  },
  setIsAdmin(value) {
    if (typeof window !== 'undefined') {
      return localStorage.setItem(getKey('IsAdmin'), value.toString());
    }
  },
  getIsAdmin() {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(getKey('IsAdmin')) === 'true';
    }
  },
};
